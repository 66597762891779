<style lang="less" scoped>
.transport_input {
  width: 160px;
}
</style>
<template>
  <a-drawer title="查看" width="66%" :placement="placement" :closable="true" :visible="visible" @close="onSwitch(false)">
    <div class="suy">
      <div>
        <div class=" ">
          <div class="suy_title textindent">
            <h2 style="width:70%;">运输车信息</h2>
          </div>
        </div>
        <div class="suy_table_title">
          <a-table :data-source="BIGlistData" :rowKey="(record,index)=>{return index}" v-model:columns="BIGcolumns" size="small">
          </a-table>
        </div>
      </div>

      <div v-if="translistData && translistData.length > 0">
        <div class=" suy_bod_b">
          <div class="suy_title textindent">
            <h2>已安排车</h2>
          </div>
        </div>
        <div class="suy_table_title">
          <a-table :data-source="translistData" :rowKey="(record,index)=>{return index}" :pagination="pagination" @change="handleTableChange" v-model:columns="vehicleIdcolumns" size="small">
            <template #brand_model="{ record }">
              {{record.brand}} {{record.model}}
            </template>
            <template #vinNo="{ record }">
              <span @click="getVehicleDetails(record.orderVehicleId)">{{record.vinNo}}</span>
            </template>
          </a-table>
        </div>

      </div>

      <div v-if="translistDataHis && translistDataHis.length >0">
        <div class=" suy_bod_b">
          <div class="suy_title textindent">
            <h2>历史安排车辆</h2>
          </div>

        </div>
        <div class="suy_table_title">
          <a-table :data-source="translistDataHis" :rowKey="(record,index)=>{return index}" v-model:columns="vehicleIdHiscolumns" size="small">
            <template #brand_model="{ record }">
              {{record.brand}} {{record.model}}
            </template>
            <template #vinNo="{ record }">
              <span @click="getVehicleDetails(record.orderVehicleId)">{{record.vinNo}}</span>
            </template>
          </a-table>
        </div>
      </div>

      <div>
        <div class=" suy_bod_b">
          <div class="suy_title textindent">
            <h2>运输路线详情</h2>
          </div>
        </div>
        <div class="suy_table_title">
          <div v-if="transTaskGpsLine === undefined">
            <label>暂无信息</label>
          </div>
          <div v-else>
            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>总运输里程（Km）: </label>
                <b>{{transTaskGpsLine.totalTransportKm}}</b>
              </a-col>
              <a-col :span="8">
                <label>预计需时（小时）: </label>
                <b>{{transTaskGpsLine.totalTransportTime}}</b>
              </a-col>
              <a-col :span="8">
                <label>发车时间: </label>
                <b>{{transTaskGpsLine.departureTime}}</b>
              </a-col>
            </a-row>
            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>当前位置: </label>
                <b>{{transTaskGpsLine.latestLocation}}</b>
              </a-col>
              <a-col :span="8">
                <label>记录时间（小时）: </label>
                <b>{{transTaskGpsLine.transportTime}}</b>
              </a-col>
              <a-col :span="8">
                <label>已行驶时间（小时）: </label>
                <b>{{transTaskGpsLine.transportTime}}</b>
              </a-col>
            </a-row>

            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>系统设置每天应行驶里程（Km）: </label>
                <b>{{transTaskGpsLine.defaultKm}}</b>
              </a-col>
              <a-col :span="8">
                <label>实际剩余公里数（Km）: </label>
                <b>{{transTaskGpsLine.remainingKm}}</b>
              </a-col>
              <a-col :span="8">
                <label>是否超期: </label>
                <a-tag :color="overDueColor">{{transTaskGpsLine.isOverDue.label}}</a-tag>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="visiblemodal" title="请填写单台运车费用" :maskClosable='false' @ok="onClick('',8)">
      <a-input v-model:value="freight" type='number' size="large" />
    </a-modal>
    <a-modal v-model:visible="transfermodal" title="请填写中途到达地点" @ok="onClick('',2)">
      <a-input v-model:value="transfer.address" size="large" />
    </a-modal>
  </a-drawer>
  <orderDetails ref="orderDetails" />
</template>
<script>
import transportType from '../TransportType/TransportType.json'
import { incomplete, detail } from '@/api/transport/truck'
import { vehicleId, vehicleIdHis } from '@/api/transport/vehicle'
import { reactive, toRefs, ref, computed } from 'vue'
import orderDetails from '@/views/transport/TransportType/orderDetails'
export default {
  components: { orderDetails },
  data () {
    return {
      dataset: transportType.TransportStatusEnum,
      datasetType: transportType.TransportTypeEnum
    }
  },
  setup (props) {
    const state = reactive({
      orderDetails: null,
      transfer: {
        address: ''
      },
      visiblemodal: false,
      transfermodal: false,
      freight: 0,
      visible: false,
      transTruckId: '',
      formRef: ref(),
      orderNum: '0',
      loading: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      formState: {
        carNo: '',
        driver: { label: '', value: 1 }
      },
      transportForm: {
        carNo: '222',
        driver: { label: '', value: 1 }
      },
      overDueColor: '#87d068',
      listData: [],
      BIGlistData: [],
      transTaskGpsLine: undefined,
      translistData: [],
      translistDataHis: [],
      columns: [
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },

        {
          title: '车辆品牌',
          dataIndex: 'brand',
          key: 'brand',
          ellipsis: true
        },

        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model',
          ellipsis: true
        },

        {
          title: '是否在板',
          dataIndex: 'isInTruck.label',
          key: 'isInTruck.label',
          ellipsis: true
        },
        {
          title: '运输状态',
          dataIndex: 'transStatus.label',
          key: 'transStatus.label',
          ellipsis: true
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo',
          ellipsis: true
        }
      ],
      BIGcolumns: [
        {
          title: '车牌号',
          dataIndex: 'carNo',
          key: 'carNo',
          ellipsis: true
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName',
          ellipsis: true
        },
        {
          title: '司机',
          dataIndex: 'driverName',
          key: 'driverName',
          ellipsis: true
        },
        {
          title: '司机电话',
          dataIndex: 'driverTel',
          key: 'driverTel',
          ellipsis: true
        },
        {
          title: '线路名称',
          dataIndex: 'lineName',
          key: 'lineName',
          ellipsis: true
        }

      ],
      vehicleIdcolumns: [
        // {
        //   title: '订单id',
        //   dataIndex: 'transOrderTruckVehicleId',
        //   key: 'transOrderTruckVehicleId',
        //   ellipsis: true
        // },
        {
          title: '车辆型号',
          dataIndex: 'brand_model',
          slots: {
            customRender: 'brand_model'
          }
        },
        {
          title: '小车车牌',
          dataIndex: 'vinNo',
          key: 'vinNo',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '单台运费',
          dataIndex: 'transportFee',
          key: 'transportFee',
          ellipsis: true
        },
        {
          title: '装车人',
          dataIndex: 'loadingOperator',
          key: 'loadingOperator',
          ellipsis: true
        },
        {
          title: '起始地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        }
      ],
      vehicleIdHiscolumns: [
        // {
        //   title: '订单id',
        //   dataIndex: 'transOrderTruckVehicleId',
        //   key: 'transOrderTruckVehicleId',
        //   ellipsis: true
        // },
        {
          title: '车辆型号',
          dataIndex: 'brand_model',
          slots: {
            customRender: 'brand_model'
          }
        },
        {
          title: '小车车牌',
          dataIndex: 'vinNo',
          key: 'vinNo',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '单台运费',
          dataIndex: 'transportFee',
          key: 'transportFee',
          ellipsis: true
        },
        {
          title: '装车人',
          dataIndex: 'loadingOperator',
          key: 'loadingOperator',
          ellipsis: true
        },
        {
          title: '起始地',
          dataIndex: 'startAddress',
          key: 'startAddress',
          ellipsis: true
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress',
          ellipsis: true
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          key: 'unloadingOperator',
          ellipsis: true
        },
        {
          title: '卸车时间',
          dataIndex: 'unloadingTime',
          key: 'unloadingTime',
          ellipsis: true
        }
      ],
      onTranslate: true,
      onTransfer: true,

      form: {},
      getname: ''
    })

    const onSwitch = (parameter) => {
      state.visible = parameter
    }
    const ontransTruckId = (parameter) => {
      state.transTruckId = parameter
    }

    const loadData = () => {
      state.loading = true
      detail(
        state.transTruckId
      ).then((res) => {
        if (res.code === 10000) {
          state.BIGlistData[0] = res.data
          if (res.data.gpsLine !== undefined && res.data.gpsLine !== null) {
            state.transTaskGpsLine = res.data.gpsLine
            if (state.transTaskGpsLine.isOverDue.value === 1) {
              state.overDueColor = '#f50'
            }
          } else {
            state.transTaskGpsLine = undefined
          }
        }
      })
      incomplete({
        ...state.formState,
        name: state.formState.name
      }).then((res) => {
        state.listData = res.data.records
      })

      vehicleId(
        state.transTruckId, ({
          current: state.pagination.current,
          size: state.pagination.pageSize
        })
      ).then((res) => {
        state.translistData = res.data.records
        state.pagination.current = res.data.current
        state.pagination.pageSize = res.data.size
        state.pagination.total = res.data.total
      })
      vehicleIdHis(
        state.transTruckId, {}
      ).then((res) => {
        state.translistDataHis = res.data.records
      })
      if (state.BIGlistData.length > 0) {
        state.onTranslate = computed(() => state.BIGlistData[0].truckStatus.value === 2)
        state.onTransfer = computed(() => state.BIGlistData[0].truckStatus.value === 2 && state.dataVehicleIds.length > 0)
      }
      state.loading = false
    }
    const getVehicleDetails = (orderVehicleId) => {
      state.orderDetails.onVehicleDetail(orderVehicleId)
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    return {
      ...toRefs(state),
      getVehicleDetails,
      onSwitch,
      handleTableChange,
      ontransTruckId,
      loadData
    }
  }
}
</script>
