<style lang="less" scoped>
.data_button {
  width: 100%;
  text-align: center;
  border: 0;
}
.ant-typography {
  background-color: #f00;
}
</style>
<template>
  <a-modal v-model:visible="visiblemodal" title="车辆详情" v-if="visiblemodal" @ok="onClick(positions,11)" :footer="false" width="85%">
    <div>
      <div class="suy_table_title">
        <div v-if="vehicleDetail === undefined">
          <label>暂无信息</label>
        </div>
        <div v-else>
          <a-button loading="loading" v-if="loading" class="data_button">数据加载中</a-button>
          <div v-else>
            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>收车人姓名: </label>
                <b>{{vehicleDetail.picker}}</b>
              </a-col>
              <a-col :span="8">
                <label>收车人电话: </label>
                <b>{{vehicleDetail.pickerMobile}}</b>
              </a-col>
              <a-col :span="8">
                <label>收车人身份证: </label>
                <b>{{vehicleDetail.pickerCertificateNumber}}</b>
              </a-col>
            </a-row>
            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>归属区域: </label>
                <b>{{vehicleDetail.officeOrgName}}</b>
              </a-col>
              <a-col :span="8">
                <label>起运地: </label>
                <b>{{vehicleDetail.startAddress}}</b>
              </a-col>
              <a-col :span="8">
                <label>目的地: </label>
                <b>{{vehicleDetail.endAddress}}</b>
              </a-col>
            </a-row>

            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>业务员: </label>
                <b>{{vehicleDetail.salesman}}</b>
              </a-col>
              <a-col :span="8">
                <label>结算方式: </label>
                <b>{{vehicleDetail.orderSettlement && vehicleDetail.orderSettlement.label}}</b>
              </a-col>
              <a-col :span="8">
                <label>合同总金额: </label>
                <b>{{vehicleDetail.contractAmt}}</b>
                <b v-if="vehicleDetail.paymentArrival > 0"> 订单到付：<a-tag color="red">{{vehicleDetail.paymentArrival}}</a-tag></b>
                <b v-if="vehicleDetail.refundFee > 0"> 返款：<a-tag color="red">{{vehicleDetail.refundFee}}</a-tag></b>
              </a-col>
            </a-row>
            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>车架号: </label>
                <b>{{vehicleDetail.vinNo}}</b>
              </a-col>
              <a-col :span="8">
                <label>当前状态: </label>
                <b>{{vehicleDetail.transStatus && vehicleDetail.transStatus.label}}</b>
              </a-col>
              <a-col :span="8">
                <label>产品名: </label>
                <b>{{vehicleDetail.productName}}</b>
              </a-col>
            </a-row>
            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>推送时间: </label>
                <b>{{vehicleDetail.pushTime}}</b>
              </a-col>
              <a-col :span="8" v-if="vehicleDetail.paymentArrival > 0">
                <label>等通知放车: </label>
                <b><a-tag color="red">是</a-tag></b>
              </a-col>
              <a-col :span="8" v-if="vehicleDetail.isTakeOut.value === 1">
                <label>是否送车: </label>
                <b>
                  <a-tag color="red">{{vehicleDetail.isTakeOut.label}}</a-tag>
                </b>
                <b>{{vehicleDetail.takeOutAddress}}</b>
              </a-col>
            </a-row>
            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>小车备注: </label>
                <b>{{vehicleDetail.remark}}</b>
              </a-col>
            </a-row>
            <a-row :gutter="[8,32]">
              <a-col :span="8">
                <label>订单备注: </label>
                <b>{{vehicleDetail.orderRemark}}</b>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <a-table :row-key="record => record.vehicleId" :dataSource="vehicleDetail.details" :pagination="false">
        <a-table-column title="序号">
          <template #default="{ index }">{{index+1}}</template>
        </a-table-column>
        <a-table-column title="运输类型" data-index="transportType.label" />
        <a-table-column title="运输车牌" data-index="carNo" />
        <a-table-column title="承运商" data-index="carrierName" />
        <a-table-column title="运输费用" data-index="transportFee">
          <template #default="{record}">
            <div>
              <a-input-number v-show="!record.editStatus" v-model:value="record.transportFee" />
              <a-textarea v-show="!record.editStatus" :maxlength="200" v-model:value="reason" placeholder="请说明您要修改的原因" />
              <a-popconfirm title="确定要修改费用么，请谨慎操作 " @confirm="confirmBtn(record)" style="width:300px;" :disabled="record.editStatus">
                <a v-show="!record.editStatus">确认修改</a>
              </a-popconfirm>
              <a-divider v-if="!record.editStatus" type="vertical" />
              <span v-show="record.editStatus">{{record.transportFee}}</span> <a @click="record.editStatus = !record.editStatus">{{record.editStatus ? '编辑' : '取消'}}</a>
            </div>
          </template>
        </a-table-column>
        <a-table-column title="运输状态" data-index="truckStatus.label" />
        <a-table-column title="线路" data-index="lineName" />
        <a-table-column title="装车时间" data-index="loadingTime" />
        <a-table-column title="装车人" data-index="loadingOperator" />
        <a-table-column title="发车时间" data-index="departureTime" />
        <a-table-column title="发车人" data-index="departure" />
        <a-table-column title="卸车时间" data-index="unloadingTime" />
        <a-table-column title="卸车人" data-index="unloadingOperator" />
        <a-table-column title="卸车地" data-index="endAddress" />
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { vehicleDetail } from '@/api/transport/truck'
import { transEdit } from '@/api/trans/ops/edit'
import { conversionS } from '@/utils/util'
import { reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'
export default {
  setup () {
    const state = reactive({
      loading: false,
      visiblemodal: false,
      editStatus: false,
      reason: '',
      vehicleDetail: {
      },
      listVehicleTransData: []
    })
    const onSwitch = (parameter) => {
      state.visiblemodal = parameter
      if (parameter === false) {
        state.vehicleDetail = undefined
      } else {
      }
    }
    const onVehicleDetail = (parameter) => {
      state.loading = true
      onSwitch(true)
      vehicleDetail(
        parameter
      ).then((res) => {
        if (res.code === 10000) {
          state.vehicleDetail = res.data
          for (const key in state.vehicleDetail) {
            state.vehicleDetail[key] = conversionS(state.vehicleDetail[key])
            if (typeof state.vehicleDetail[key] === 'object') {
              for (const val in state.vehicleDetail[key]) {
                state.vehicleDetail[key][val] = conversionS(state.vehicleDetail[key][val])
              }
            }
          }
          state.vehicleDetail.details.forEach(element => {
            element.editStatus = true
          })
        } else message.error('该车没有订单号')
        state.loading = false
      })
    }
    const confirmBtn = (data) => {
      // console.log(data)
      transEdit({
        params: {
          transportTruckId: data.transTruckId,
          totvId: data.totvId,
          changeFee: data.transportFee
        },
        type: {
          value: 1
        },
        reason: state.reason,
        subType: 101
      }).then(res => {
        // console.log(res)
        if (res.code === 10000) {
          message.success(res.msg)
          state.vehicleDetail.details.forEach(element => {
            element.editStatus = true
          })
          // loadData()
        }
      }).finally(() => {
        state.reason = ''
        state.dataVehicleIds = []
        state.backVisible = false
        state.onRetreatVehicle = true
      })
    }
    return {
      ...toRefs(state),
      onSwitch,
      onVehicleDetail,
      confirmBtn,
      conversionS
    }
  }
}
</script>
